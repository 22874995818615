// import Cell from '../Cell';
import { dropdownDown, dropdownToggle, dropdownUp } from '../../../utils/utils';
import { isTextError } from '../../../utils/validation';
import Cell from '../Cell';
import './cellTextarea.scss';

export default class CellTextarea extends Cell {
    constructor($cell, options) {
        super($cell);
        this.$input = this.$cell.querySelector('.js-cell-input');
        this.startValue = this.$input.value;
        this.currentValue = this.startValue;
        this.onChange = options?.onChange;

        this.init();
    }

    init() {
        this.upLabel();
        this.$input.addEventListener('input', this.changesInput.bind(this));
    }

    changesInput() {
        !this.isInputError() ? this.successfulStatus() : this.removeSuccessfulStatus();
        this.removeErrorStatus();
        this.currentValue = this.$input.value;
        this.onChange ? this.onChange() : null;
    }

    // startCheck() {
    //     !this.isInputError() ? this.successfulStatus() : this.removeSuccessfulStatus();
    // }

    isInputError(showError = false) {
        const isError = this.isRequired ? isTextError(this.$input) : this.isRequired;

        if (showError && isError && this.isRequired) {
            this.errorStatus();
        }

        return isError;
    }

    showField(animation = true) {
        this.isRequired = this.$cell.getAttribute('data-required') !== 'false';
        this.isVisible = true;
        animation ? dropdownDown(this.$cell) : this.$cell.classList.remove('js-cell-hidden');
        this.upLabel();
    }

    hideField(animation = true) {
        this.isRequired = false;
        this.isVisible = false;
        animation ? dropdownUp(this.$cell) : this.$cell.classList.add('js-cell-hidden');
    }

    toggleVisibility(animation = true) {
        this.isVisible = !this.isVisible;
        animation ? dropdownToggle(this.$cell) : this.$cell.classList.toggle('js-cell-hidden');
        if (!this.isVisible) {
            this.isRequired = false;
        } else {
            this.isRequired = this.$cell.getAttribute('data-required') !== 'false';
        }
        this.upLabel();
    }

    isInputChanged() {
        return !(this.startValue === this.currentValue);
    }
}
