//Плавно скрывает элемент
export const dropdownUp = (el, duration = 300) => {
    //Определяем свойства для анимации, задаем длительность, вычислем высоту блока
    el.style.transitionProperty = 'height, margin, padding';
    el.style.transitionDuration = duration + 'ms';
    el.style.height = el.offsetHeight + 'px';
    el.offsetHeight;

    //Сбрасываем все значения на 0 и скрываем блок
    el.style.overflow = 'hidden';
    el.style.height = 0;
    el.style.paddingTop = 0;
    el.style.paddingBottom = 0;
    el.style.marginTop = 0;
    el.style.marginBottom = 0;

    //По окончанию анимации удаляем блок и удаляем все добавленные инлайн свойства и класс о процессе анимации
    window.setTimeout(() => {
        el.style.display = 'none';
        el.style.removeProperty('height');
        el.style.removeProperty('padding-top');
        el.style.removeProperty('padding-bottom');
        el.style.removeProperty('margin-top');
        el.style.removeProperty('margin-bottom');
        el.style.removeProperty('overflow');
        el.style.removeProperty('transition-duration');
        el.style.removeProperty('transition-property');
    }, duration);
};

//Плавно показывает элемент
export const dropdownDown = (el, duration = 300) => {
    //Проверяем скрыт блок или нет и показываем его
    el.style.removeProperty('display');
    let display = window.getComputedStyle(el).display;
    if (display === 'none') {
        display = 'block';
    }
    el.style.display = display;

    //Определяем всоту на которую будет анимироваца блок
    let height = el.offsetHeight;

    //Делаем блок скрытым и задем начальные свойства
    el.style.height = 0;
    el.style.paddingTop = 0;
    el.style.paddingBottom = 0;
    el.style.marginTop = 0;
    el.style.marginBottom = 0;
    el.style.overflow = 'hidden';
    el.offsetHeight;

    //Определяем, что анимируем и на какую высоту
    el.style.transitionProperty = 'height, margin, padding';
    el.style.transitionDuration = duration + 'ms';
    el.style.height = height + 'px';

    //Возвращаем заданные значения в css для отступов
    el.style.removeProperty('padding-top');
    el.style.removeProperty('padding-bottom');
    el.style.removeProperty('margin-top');
    el.style.removeProperty('margin-bottom');

    //Удаляем оставшиеся свойства после анимации
    window.setTimeout(() => {
        el.style.removeProperty('height');
        el.style.removeProperty('overflow');
        el.style.removeProperty('transition-duration');
        el.style.removeProperty('transition-property');
    }, duration);
};

//Плавно переключает видимость элемента
export const dropdownToggle = (el, duration = 300) => {
    if (window.getComputedStyle(el).display === 'none') {
        return dropdownDown(el, duration);
    } else {
        return dropdownUp(el, duration);
    }
};

//Блокируем body с удалением скролла
export const blockBody = () => {
    const body = document.body;
    const blockPaddingValue = window.innerWidth - body.clientWidth + 'px';

    body.style.overflow = 'hidden';
    body.style.paddingRight = blockPaddingValue;
};

//Разблокирует body
export const unBlockBody = () => {
    const body = document.body;

    //Разблокируем боди после окончания анимации
    setTimeout(function () {
        body.style.overflow = 'visible';
        body.style.paddingRight = '0';
    }, 500);
};

//Переключение блокировки body
export const toggleBlockBody = () => {
    const body = document.body;

    body.style.overflow === 'hidden' ? unBlockBody() : blockBody();
};

//Преобразует дату из маски формата dd.mm.yyyy в формат ISO yyyy-mm-dd или yyyy/mm/dd
export const convertsDateToIsoFormat = (date, separator = '-') => {
    let newDate;
    newDate = date.split('.').reverse().join(separator);

    return newDate;
};

export const fadeIn = (el, timeout, display) => {
    el.style.opacity = 0;
    el.style.display = display || 'block';
    el.style.transition = `opacity ${timeout}ms`;
    setTimeout(() => {
        el.style.opacity = 1;
    }, 10);
};

export const fadeOut = (el, timeout) => {
    el.style.opacity = 1;
    el.style.transition = `opacity ${timeout}ms`;
    el.style.opacity = 0;

    setTimeout(() => {
        el.style.display = 'none';
    }, timeout);
};

//Возвращает функцию, которая, пока она продолжает вызываться, не будет запускаться.
//Она будет вызвана один раз через N миллисекунд после последнего вызова.
//Если передан аргумент `immediate` (true), то она запустится сразу же при первом запуске функции.
export function debounce(func, wait, immediate) {
    let timeout;

    return function executedFunction() {
        const context = this;
        const args = arguments;

        const later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };

        const callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) func.apply(context, args);
    };
}

//Удаляет пробелы и значения после последнего пробела
export const deleteSingAfterSpace = (value) => {
    let newString;
    console.log(666, value.split(' '));
    newString = value.split(' ').slice(0, -1).join('');
    return newString;
};

//Получаем полный возраст по дате 2002-09-22
export const getCurrentAge = (date) => {
    return ((new Date().getTime() - new Date(date)) / (24 * 3600 * 365.25 * 1000)) | 0;
};

// //Преобразуем в десятичное значение сумму
export const convertSum = (sum, maxNumber) => {
    const formatter = new Intl.NumberFormat('ru-RU', {
        style: 'decimal',
        maximumFractionDigits: maxNumber,
    });

    const result = formatter.format(sum);
    return result;
};

export const dateAgo = (dateStart, dateEnd) => {
    const diffDate = new Date(dateEnd - dateStart);
    return {
        year: diffDate.toISOString().slice(0, 4) - 1970,
        month: diffDate.getMonth(),
        day: diffDate.getDate() - 1,
    };
};

export const dateAgo2 = (d1, d2) => {
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth() + 1;
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
};
