import { dropdownDown, dropdownUp, dropdownToggle } from '../../../utils/utils';
import './сheckbox.scss';

export default class Checkbox {
    constructor(cell, options) {
        this.$cell = cell;
        this.$input = this.$cell.querySelector('.js-checkbox-input');
        this.$errorMessage = this.$cell.querySelector('.js-checkbox-error-message');
        this.isVisible = !this.$cell.classList.contains('js-cell-hidden');
        this.isRequired = this.isVisible ? this.$input.getAttribute('data-required') !== 'false' : false;
        this.startValue = this.$input.checked;
        this.currentValue = this.startValue;
        this.onChange = options?.onChange;

        this.init();
    }

    init() {
        this.$input.addEventListener('change', this.changeStatus.bind(this));
    }

    changeStatus() {
        this.removeErrorStatus();
        this.currentValue = this.$input.checked;
        this.onChange ? this.onChange() : null;
    }

    isInputError(showError = false) {
        const isError = this.isRequired ? !this.$input.checked : this.isRequired;

        if (showError && isError && this.isRequired) {
            this.errorStatus();
        }

        return isError;
    }

    showField(animation = true) {
        this.isVisible = true;
        this.isRequired = this.$input.getAttribute('data-required') !== 'false';
        animation ? dropdownDown(this.$cell) : this.$cell.classList.remove('js-checkbox-hidden');
    }

    hideField(animation = true) {
        this.isVisible = false;
        this.isRequired = false;
        animation ? dropdownUp(this.$cell) : this.$cell.classList.add('js-checkbox-hidden');
    }

    toggleVisibility(animation = true) {
        this.isVisible = !this.isVisible;
        animation ? dropdownToggle(this.$cell) : this.$cell.classList.toggle('js-cell-hidden');

        if (!this.isVisible) {
            this.isRequired = false;
        } else {
            this.isRequired = this.$input.getAttribute('data-required') !== 'false';
        }
    }

    errorStatus() {
        dropdownDown(this.$errorMessage);
    }

    removeErrorStatus() {
        dropdownUp(this.$errorMessage);
    }

    isInputChanged() {
        return !(this.startValue === this.$input.checked);
    }
}
