import './paramRadioButton.scss';

export default class ParamRadioButton {
    constructor(cell, options) {
        this.$cell = cell;
        this.$paramRadioButton = this.$cell.querySelector('.js-param-radiobutton');
        this.$inputs = this.$paramRadioButton.querySelectorAll('.js-param-radiobutton-input');
        this.onChange = options?.onChange;
        this.$input;
        this.currentValue;
        this.init();
    }

    init() {
        this.$inputs.forEach((input) => {
            input.addEventListener('change', this.changeStatus.bind(this));
            this.getCheckedInput();
        });
    }

    changeStatus() {
        this.getCheckedInput();
        this.onChange ? this.onChange() : null;
    }

    isInputError(showError = false) {
        const isError = false;

        if (showError && isError && this.isRequired) {
            this.errorStatus();
        }

        return isError;
    }

    isInputChanged() {
        return true;
    }

    getCheckedInput() {
        this.$inputs.forEach((input) => {
            input.checked && (this.$input = input);
        });
        this.currentValue = this.$input.value;
    }
}
