import Inputmask from 'inputmask';
import 'air-datepicker/air-datepicker.css';
import './datepickerMinMax.scss';
import AirDatepicker from 'air-datepicker';
import { convertsDateToIsoFormat } from '../../../utils/utils';
import CellInput from '../CellInput';

export default class DatepickerMinMax extends CellInput {
    constructor($cell, options) {
        super($cell, options);

        this.datepicker = new AirDatepicker(this.$input, {
            onSelect: this.changesInput.bind(this),
            startDate: convertsDateToIsoFormat(this.$input.value),
            onChangeViewDate: this.changesMonth.bind(this),
            autoClose: true,
        });
        this.mask;
        this.init();
    }

    init() {
        const todayDate = new Date();
        const currentMonth = todayDate.getMonth();
        const currentYear = todayDate.getFullYear();
        this.mask = new Inputmask('99.99.9999', { showMaskOnHover: false }).mask(this.$input);
        this.startCheck();
        !this.isRequired ? this.$cell.classList.add('js-no-required') : this.$cell.classList.remove('js-no-required');
        this.$input.addEventListener('input', this.changesInput.bind(this));
        this.$input.addEventListener('focus', this.focusInput.bind(this));
        this.$input.addEventListener('blur', this.blurInput.bind(this));
        this.changesMonth({ month: currentMonth, year: currentYear });
    }

    changesInput() {
        !this.isInputError() ? this.successfulStatus() : this.removeSuccessfulStatus();
        this.$input.value.length > 0 ? this.upLabel() : this.downLabel();
        this.removeErrorStatus();
        this.onChange ? this.onChange(this.datepicker.selectedDates.length > 0 ? this.datepicker.selectedDates : [convertsDateToIsoFormat(this.$input.value)]) : null;
        this.currentValue = this.$input.value;
    }

    // changesMonth({ month, year }) {
    // 	console.log(month, year)
    //     const todayDate = new Date();
	// 	if(todayDate.getDate() === 28 || todayDate.getDate() === 29 || todayDate.getDate() === 30){
	// 		todayDate.setMonth(month + 1);
	// 		todayDate.setDate(1);
	// 	}
	// 	else{
	// 		todayDate.setMonth(month)
	// 		todayDate.setDate(todayDate.getDate() + 1);
	// 	}
	// 	const finishDate = todayDate.getDate() === 29 || todayDate.getDate() === 30 || todayDate.getDate() === 31 ? new Date(year, month + 1, 28) : new Date(year, month, 28)
    //     const endDate = finishDate;
    //     // Убрал setTimeout что бы дождаться сначала вызова init функции
    //     setTimeout(() => {
    //         this.datepicker.update({
    //             minDate: todayDate,
    //             maxDate: endDate,
    //             selectOtherMonths: false,
    //             disableNavWhenOutOfRange: false,
	// 		});
    //         this.$input.value = this.currentValue;
    //     }, 1000);
    // }

	changesMonth({ month, year }) {
		console.log(month, year)
		const todayDate = new Date();
		todayDate.setDate(todayDate.getDate() + 1)
		// Убрал setTimeout что бы дождаться сначала вызова init функции
		setTimeout(() => {
			this.datepicker.update({
				minDate: todayDate,
				selectOtherMonths: false,
				disableNavWhenOutOfRange: false,
				onRenderCell({date, cellType}) {
					// Disable all 12th dates in month
					if (cellType === 'day') {
						if (date.getDate() === 29 || date.getDate() === 30 || date.getDate() === 31) {
							return {
								disabled: true,
								classes: 'disabled-class',
								attrs: {
									title: 'Нельзя выбрать'
								}
							}
						}
					}
				}
			});
			this.$input.value = this.currentValue;
		}, 1000);
	}

    isInputError(showError = false) {
        const isError = this.isRequired ? !this.mask.isComplete() : this.isRequired;

        if (showError && isError && this.isRequired) {
            this.errorStatus();
        }

        return isError;
    }
}
