import { dropdownDown, dropdownToggle, dropdownUp } from '../../../utils/utils';
import Cell from '../Cell';
import './cellSelect.scss';

export default class CellSelect extends Cell {
    constructor($cell, options) {
        super($cell);
        this.$cellSelect = $cell.querySelector('.js-cell-select');
        this.$cellSelectHeader = this.$cellSelect.querySelector('.js-cell-select-header');
        this.$cellSelectBody = this.$cellSelect.querySelector('.js-cell-select-body');
        this.$cellSelectItems = this.$cellSelect.querySelectorAll('.js-cell-select-item');
        this.$cellSelectCurrent = this.$cellSelect.querySelector('.js-cell-select-current');
        this.$cellSelectInput = this.$cellSelect.querySelector('.js-cell-input');
        this.$startValue = this.$cell.querySelector('.js-cell-current-value');
        this.selectedItemIndex;
        this.startValue = this.$cellSelectInput.value;
        this.currentValue = this.startValue;
        this.visibleStartValue = options?.visibleStartValue;
        this.selectionAction = options?.chosenItem;

        this.init();
    }

    init() {
        if (this.visibleStartValue && this.$cellSelectInput.value.length > 0) {
            this.$cellSelectCurrent.style.opacity = 1;
            this.upLabel();
            this.successfulStatus();
        }
        this.$cellSelectBody.addEventListener('click', this.choosingValue.bind(this));
        this.$cellSelectHeader.addEventListener('click', this.toggleBody.bind(this));
		this.$cellSelectInput.value && this.addInputValue(this.$cellSelectCurrent.textContent, this.$cellSelectInput.value);
    }

    toggleBody() {
        this.$cellSelect.classList.toggle('is-active');
        dropdownToggle(this.$cellSelectBody);
    }

    choosingValue(evt) {
        const targetValue = evt.target.textContent.trim();
        const targetItem = evt.target.classList.contains('js-cell-select-item') ? evt.target : evt.target.closest('.js-cell-select-item');
        const itemValue = targetItem.getAttribute('data-item-value');
        const itemIndex = targetItem.getAttribute('data-index');

        this.$cellSelectItems.forEach((item) => {
            item.classList.remove('is-chosen');
        });

        targetItem.classList.add('is-chosen');
        this.$cellSelectCurrent.textContent = targetValue;
        this.$cellSelectCurrent.style.opacity = 1;
        this.$cellSelectInput.value = itemValue;
        this.currentValue = itemValue;
        this.selectedItemIndex = itemIndex;
        this.toggleBody();
        this.upLabel();
        this.removeErrorStatus();
        this.successfulStatus();
        this.selectionAction ? this.selectionAction() : null;
    }

    addValue(value) {
        this.$cellSelectCurrent.textContent = value;
        this.$cellSelectInput.value = value;
        this.currentValue = value;
        this.upLabel();
        this.removeErrorStatus();
    }

    addStartValue(value) {
        this.startValue = value;
        // this.$cellSelectInput.value = valueForBackend;

        if (this.$startValue) {
            this.$startValue.textContent = value;
        }
    }

    addInputValue(value, valueForBackend) {
        this.$cellSelectCurrent.textContent = value;
        this.$cellSelectInput.value = valueForBackend;
        this.currentValue = value;
        this.$cellSelectCurrent.style.opacity = 1;
        this.upLabel();
        this.removeErrorStatus();
        this.successfulStatus();
    }

    isInputError(showError = false) {
        const isError = this.isRequired ? this.$cellSelectInput.value.length === 0 : this.isRequired;

        if (showError && isError && this.isRequired) {
            this.errorStatus();
        }

        return isError;
    }

    unblockSelect() {
        this.removeDisabledStatus();
    }

    blockSelect() {
        this.disabledStatus();
    }

    showField(animation = true) {
        this.isRequired = this.$cellSelectInput.getAttribute('data-required') !== 'false';
        this.isVisible = true;
        animation ? dropdownDown(this.$cell) : this.$cell.classList.remove('js-cell-hidden');
        this.$cellSelectInput.value.length > 0 ? this.upLabel() : this.downLabel();
    }

    hideField(animation = true) {
        this.isRequired = false;
        this.isVisible = false;
        animation ? dropdownUp(this.$cell) : this.$cell.classList.add('js-cell-hidden');
    }

    toggleVisibility(animation = true) {
        this.isVisible = !this.isVisible;
        animation ? dropdownToggle(this.$cell) : this.$cell.classList.toggle('js-cell-hidden');
        if (!this.isVisible) {
            this.isRequired = false;
        } else {
            this.isRequired = this.$cellSelectInput.getAttribute('data-required') !== 'false';
        }
        this.$cellSelectInput.value.length > 0 ? this.upLabel() : this.downLabel();
    }

    showError(message) {
        this.errorStatus(message);
    }

    isInputChanged() {
        return !(this.startValue === this.currentValue);
    }

    addSuccessfulSelect(message) {
        message && (this.$cellSelectCurrent.textContent = message);
        this.$cellSelectCurrent.style.opacity = 1;
        this.upLabel();
        this.successfulStatus();
    }

    removeSuccessfulSelect(message) {
        message && (this.$cellSelectCurrent.textContent = message);
        this.$cellSelectCurrent.style.opacity = 0;
        this.downLabel();
        this.removeSuccessfulStatus();
    }
}
