import Api from '../../../requests/Api';
// import { dropdownDown, dropdownUp } from '../../../utils/utils';
import CellSelect from '../CellSelect';
import './cellFias.scss';

export default class CellFias extends CellSelect {
    constructor($cell, options) {
        super($cell, options);
        this.$postCode = this.$cellSelect.querySelector('.js-cell-fias-post-code');
        this.$region = this.$cellSelect.querySelector('.js-cell-fias-region');
        this.$city = this.$cellSelect.querySelector('.js-cell-fias-city');
        this.$street = this.$cellSelect.querySelector('.js-cell-fias-street');
        this.$house = this.$cellSelect.querySelector('.js-cell-fias-house');
        this.$building = this.$cellSelect.querySelector('.js-cell-fias-building');
        this.$flat = this.$cellSelect.querySelector('.js-cell-fias-flat');
        this.request = new Api();
        this.choosenAddress;
        this.choosenNumber;
        this.splitData = {
            PostCode: '',
            Region: '',
            City: '',
            Street: '',
            House: '',
            Building: '',
            Flat: '',
        };
    }

    init() {
        this.startCheck();
        this.$cellSelectInput.addEventListener('input', this.changesInput.bind(this));
        this.$cellSelectInput.addEventListener('focus', this.focusInput.bind(this));
        this.$cellSelectInput.addEventListener('blur', this.blurInput.bind(this));
        this.$cellSelectBody.addEventListener('click', this.choosingValue.bind(this));
    }

    changesInput() {
        this.removeErrorStatus();
        if (this.$cellSelectInput.value) {
            this.request.getFiasAddressOneString(this.$cellSelectInput.value).then((res) => {
                this.choosenAddress = res;
                this.$cellSelectBody.innerHTML = '';
                res.forEach((address, index) => {
                    this.$cellSelectBody.insertAdjacentHTML(
                        'beforeend',
                        `
						<div class="cell-fias__item js-cell-select-item" data-item-value="0" data-index="${index}">
							<span class="cell-fias__item-info js-cell-select-item-info">${address.value}</span>
							<svg class="cell-fias__body-icon" width="11px" height="8px">
								<use xlink:href="#icon-cell-select-body"></use>
							</svg>
						</div>
						`
                    );
                });
            });
            this.$cellSelect.classList.add('is-active');
        }
        // this.onChange ? this.onChange() : null;
    }

    startCheck() {
        this.$cellSelectInput.value.length > 0 ? this.upLabel() : this.downLabel();
    }

    focusInput() {
        this.upLabel();
    }

    blurInput() {
        // this.$cellSelect.classList.remove('is-active');
        if (this.$cellSelectInput.value.length === 0) {
            this.downLabel();
        }
    }

    choosingValue(evt) {
        const targetValue = evt.target.textContent.trim();
        const targetItem = evt.target.classList.contains('js-cell-select-item') ? evt.target : evt.target.closest('.js-cell-select-item');

        this.choosenNumber = targetItem.getAttribute('data-index');
        this.$cellSelectInput.value = targetValue;
        this.$cellSelect.classList.remove('is-active');
        this.currentValue = targetValue;
        this.upLabel();
        this.removeErrorStatus();
        this.selectionAction ? this.selectionAction() : null;
        this.$city && this.splitAddress(targetItem.getAttribute('data-index'));
    }

    addValue(value) {
        this.$cellSelectInput.value = value;
        this.currentValue = value;
        this.upLabel();
        this.removeErrorStatus();
        this.successfulStatus();
    }

    // addStartValue(value) {
    //     // this.$cellSelectInput.value = value;
    //     this.startValue = value;
    //     this.currentValue = value;
    //     this.upLabel();
    //     this.removeErrorStatus();
    //     this.successfulStatus();
    // }

    addInputValue(value) {
        this.$cellSelectInput.value = value;
        this.currentValue = value;
        this.removeErrorStatus();
        if (value.length > 0) {
            this.upLabel();
            this.successfulStatus();
        }
    }

    splitAddress(elNumber) {
        const data = this.choosenAddress[elNumber];

        //Обнуляем значение перед вставкой новых
        Object.keys(this.splitData).map((key) => {
            this.splitData[key] = '';
        });

        //Сопоставляем адреса с объектом для бэка
        data.addressParts.forEach((el) => {
            switch (el.fullTypeName) {
                case 'область':
                    this.splitData.Region = el.name + ' ' + el.typeName;
                    this.$region.value = this.splitData.Region;
                    break;
                case 'республика':
                    this.splitData.Region = el.typeName + ' ' + el.name;
                    this.$region.value = this.splitData.Region;
                    break;
                case 'город':
                    this.splitData.City = el.typeName + ' ' + el.name;
                    this.$city.value = this.splitData.City;
                    break;
                case 'деревня':
                    this.splitData.City = el.typeName + ' ' + el.name;
                    this.$city.value = this.splitData.City;
                    break;
                case 'поселок':
                    this.splitData.City = el.typeName + ' ' + el.name;
                    this.$city.value = this.splitData.City;
                    break;
                case 'дачный поселок':
                    this.splitData.City = el.typeName + ' ' + el.name;
                    this.$city.value = this.splitData.City;
                    break;
                case 'село':
                    this.splitData.City = el.typeName + ' ' + el.name;
                    this.$city.value = this.splitData.City;
                    break;
                case 'сельское поселение':
                    this.splitData.City = el.typeName + ' ' + el.name;
                    this.$city.value = this.splitData.City;
                    break;
                case 'хутор':
                    this.splitData.City = el.typeName + ' ' + el.name;
                    this.$city.value = this.splitData.City;
                    break;
                case 'садовое неком-е товарищество':
                    this.splitData.City = el.typeName + ' ' + el.name;
                    this.$city.value = this.splitData.City;
                    break;
                case 'выселки(ок)':
                    this.splitData.City = el.typeName + ' ' + el.name;
                    this.$city.value = this.splitData.City;
                    break;
                case 'местечко':
                    this.splitData.City = el.typeName + ' ' + el.name;
                    this.$city.value = this.splitData.City;
                    break;
                case 'территория':
                    this.splitData.City = el.typeName + ' ' + el.name;
                    this.$city.value = this.splitData.City;
                    break;
                case 'улица':
                    this.splitData.Street = el.typeName + ' ' + el.name;
                    this.$street.value = this.splitData.Street;
                    break;
                case 'проспект':
                    this.splitData.Street = el.typeName + ' ' + el.name;
                    this.$street.value = this.splitData.Street;
                    break;
				case 'шоссе':
					this.splitData.Street = el.typeName + ' ' + el.name;
					this.$street.value = this.splitData.Street;
					break;
                case 'площадь':
                    this.splitData.Street = el.typeName + ' ' + el.name;
                    this.$street.value = this.splitData.Street;
                    break;
                case 'переулок':
                    this.splitData.Street = el.typeName + ' ' + el.name;
                    this.$street.value = this.splitData.Street;
                    break;
				case 'дом':
					this.splitData.House = el.typeName + ' ' + el.name
					//Object.hasOwn(el, 'sublevels') === false ? this.splitData.House = el.typeName + ' ' + el.name : this.splitData.House = el.typeName + ' ' + el.name + ' ' + el.sublevels[0].typeName + ' ' + el.sublevels[0].name;
                    this.$house.value = this.splitData.House;
                    break;
                case 'корпус':
                    this.splitData.Building = el.typeName + ' ' + el.name;
                    this.$building.value = this.splitData.Building;
                    break;
                case 'квартира':
                    this.splitData.Flat = el.typeName + ' ' + el.name;
                    this.$flat.value = this.splitData.Flat;
                    break;
				case 'помещение':
					this.splitData.Flat = el.typeName + ' ' + el.name;
					this.$flat.value = this.splitData.Flat;
					break;
                default:
                    break;
            }
        });
    }
}
