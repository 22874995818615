import { dropdownDown, dropdownUp } from '../../../utils/utils';
import './paramCheckbox.scss';

export default class ParamCheckbox {
	constructor(cell, options) {
		this.$cell = cell;
		this.$input = this.$cell.querySelector('.js-cell-input');
		this.$errorMessage = this.$cell.querySelector('.js-param-checkbox-error-message');
		this.isRequired = this.$input.getAttribute('data-required') !== 'false';
		this.content = this.$cell.querySelector('.param-checkbox__name').innerText;
		this.startValue = this.$input.checked;
		this.currentValue = this.startValue;
		this.onChange = options?.onChange;
		this.init();
	}

	init() {
		this.$input.addEventListener('change', this.changeStatus.bind(this));
	}

	changeStatus() {
		this.removeErrorStatus();
		this.onChange ? this.onChange() : null;
		this.currentValue = this.$input.checked;
	}

	isInputError(showError = false) {
		const isError = this.isRequired ? !this.$input.checked : this.isRequired;

		if (showError && isError && this.isRequired) {
			this.errorStatus();
		}

		return isError;
	}

	errorStatus() {
		dropdownDown(this.$errorMessage);
	}

	removeErrorStatus() {
		dropdownUp(this.$errorMessage);
	}

	isInputChanged() {
		return !(this.startValue === this.$input.checked);
	}
}
