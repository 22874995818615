import { dropdownDown, dropdownToggle, dropdownUp } from '../../../utils/utils';
import './cell.scss';

export default class Cell {
	constructor(cell) {
		this.$cell = cell;
		this.$cellNameText = this.$cell.querySelector('.js-cell-name-text');
		this.$errorMessage = this.$cell.querySelector('.js-error-message');
		this.isVisible = !this.$cell.classList.contains('js-cell-hidden');
		this.isRequired = this.isVisible ? this.$cell.getAttribute('data-required') !== 'false' : false;
	}

	upLabel() {
		this.changeBorderWidth();
		this.$cell.classList.add('js-label-up');
	}

	downLabel() {
		const $cellBorderRight = this.$cell.querySelector('.js-cell-border-right');

		$cellBorderRight.style.setProperty('--width-border-right', 'calc(100% - 12px)');
		this.$cell.classList.remove('js-label-up');
	}

	successfulStatus() {
		this.$cell.classList.add('js-label-successful');
		this.changeBorderWidth();
	}

	removeSuccessfulStatus() {
		this.$cell.classList.remove('js-label-successful');
		this.changeBorderWidth();
	}

	errorStatus(errorMessage) {
		this.$cell.classList.add('js-cell-error');
		this.$errorMessage.innerHTML = 'Заполните поле';
		if (errorMessage) {
			this.$errorMessage.innerHTML = errorMessage;
		}
		dropdownDown(this.$errorMessage);
	}

	removeErrorStatus() {
		this.$cell.classList.remove('js-cell-error');
		dropdownUp(this.$errorMessage);
	}

	disabledStatus() {
		this.$cell.classList.add('js-cell-disabled');
	}

	removeDisabledStatus() {
		this.$cell.classList.remove('js-cell-disabled');
	}

	showCell(animation = true) {
		this.isRequired = this.$cell.getAttribute('data-required') !== 'false';
		this.isVisible = true;
		animation ? dropdownDown(this.$cell) : this.$cell.classList.remove('js-cell-hidden');
	}

	hideCell(animation = true) {
		this.isRequired = false;
		this.isVisible = false;
		animation ? dropdownUp(this.$cell) : this.$cell.classList.add('js-cell-hidden');
	}

	toggleVisibility(animation = true) {
		this.isVisible = !this.isVisible;
		animation ? dropdownToggle(this.$cell) : this.$cell.classList.toggle('js-cell-hidden');
		if (!this.isVisible) {
			this.isRequired = false;
		} else {
			this.isRequired = this.$cell.getAttribute('data-required') !== 'false';
			if (this.isRequired) {
				this.$cell.classList.remove('js-no-required');
				// this.$initialInput.value.length === 0 && this.$cell.$cell.classList.remove('js-label-successful');
			}
		}
	}

	changeBorderWidth() {
		const $cellBorderRight = this.$cell.querySelector('.js-cell-border-right');
		const $cellName = this.$cell.querySelector('.js-cell-name');
		const $cellLabelBuffer = this.$cell.querySelector('.js-label-buffer');

		$cellLabelBuffer.innerHTML = $cellName.innerHTML;
		const borderWidth = $cellLabelBuffer.offsetWidth + 12;
		$cellBorderRight.style.setProperty('--width-border-right', `calc(100% - ${borderWidth}px)`);
		this.checkLabelWidth();
	}

	checkLabelWidth() {
		const $cellName = this.$cell.querySelector('.js-cell-name');

		$cellName.offsetWidth > this.$cell.offsetWidth && $cellName.style.setProperty('--width-cell-name', `${this.$cell.offsetWidth - 48}px`);
	}
}
