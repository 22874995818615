import './form.scss';
import CellDatepicker from '../CellDatepicker/CellDatepicker';
import CellInputMask from '../CellInputMask/CellInputMask';
import ParamRadioButton from '../ParamRadioButton';
import CellSelect from '../CellSelect/CellSelect';
import ParamCheckbox from '../ParamCheckbox';
import CellFias from '../CellFias/CellFias';
import CellTextarea from '../CellTextarea';
import RadioButton from '../RadioButton';
import MultiDate from '../MultiDate';
import CellInput from '../CellInput';
import Checkbox from '../Checkbox';
import Loader from '../Loader';
import DatepickerMinMax from "../DatepickerMinMax";
import { dropdownDown, dropdownUp } from "../../../utils/utils";

export default class Form {
    constructor(form, submitForm, isExternalErrors) {
        this.$form = form;
        this.$formButton = this.$form.querySelector('.js-form-button');
        this.$formError = this.$form.querySelector('.js-form-error');
        this.formElements = {};
        this.formAction = submitForm;
        this.isExternalErrors = isExternalErrors;

        this.init();
    }

    init() {
        this.$form.addEventListener('submit', this.onSubmitForm.bind(this));
    }

    onSubmitForm(e) {
        e.preventDefault();
		const externalErrors = this.isExternalErrors ? this.isExternalErrors() : false;

		console.log(333, externalErrors)

		if (!externalErrors) {
			//Если ошибок нет, вызываем функцию отправки формы
			if (!this.checkForm(true)) {
				this.formAction ? this.formAction() : null;
			}
		}
    }

    checkForm(showError = false) {
        let errorForm = false;
        Object.values(this.formElements).forEach(($Element) => {
            if ($Element.isInputError(showError)) {
                errorForm = true;
            }
        });

        return errorForm;
    }

    blockingFormButton() {
        this.$formButton.classList.add('disabled');
    }

    unblockingFormButton() {
        this.$formButton.classList.remove('disabled');
    }

	showFormError() {
		dropdownDown(this.$formError);
	}

	hideFormError() {
		dropdownUp(this.$formError);
	}

    getFormData() {
        let formData = new FormData();

        Object.keys(this.formElements).forEach((key) => {
            const elClassName = this.formElements[key].$cell.getAttribute('data-class');

            if (typeof this.formElements[key].currentValue === 'object') {
                this.formElements[key].currentValue.forEach((item) => {
                    formData.append(key, item);
                });
            } else if (elClassName === 'ParamCheckbox') {
                this.formElements[key].$input.checked && formData.append(this.formElements[key].$input.name, this.formElements[key].$input.value);
            } else {
                formData.append(key, this.formElements[key].currentValue);
            }
        });

        return formData;
    }

    getUnmaskedFormData() {
        let formData = new FormData();

        Object.keys(this.formElements).forEach((key) => {
            const elClassName = this.formElements[key].$cell.getAttribute('data-class');

            if (typeof this.formElements[key].currentValue === 'object') {
                this.formElements[key].currentValue.forEach((item) => {
                    formData.append(key, item);
                });
            } else if (elClassName === 'ParamCheckbox') {
                this.formElements[key].$input.checked && formData.append(this.formElements[key].$input.name, this.formElements[key].$input.value);
            } else if (elClassName === 'CellInputMask') {
                formData.append(key, this.formElements[key].mask.unmaskedvalue());
            } else {
                formData.append(key, this.formElements[key].currentValue);
            }
        });

        return formData;
    }

    getFormDataWithChangedInputs(checkedFields) {
        let formData = new FormData();
        const groups = this.$form.querySelectorAll('.js-form-fieldset');

        Object.values(this.formElements).forEach(($Element) => {
            const cellName = $Element.$cell.getAttribute('data-name');
            const cellClass = $Element.$cell.getAttribute('data-class');

            //Проверяем есть ли поле в списке для отслеживания
            if (checkedFields?.includes(cellName)) {
                const $group = $Element.$cell.closest('.js-form-fieldset');

                //Определяем поля, которые не входят в группы для отслеживания
                if (!$group) {
                    if ($Element.isInputChanged() && $Element.isVisible) {
                        const cellName = $Element.$cell.getAttribute('data-name');

                        if (cellClass === 'MultiDate') {
                            this.formElements[cellName].$inputs.forEach((input) => {
                                formData.append(input.getAttribute('name'), input.value);
                            });
                        } else {
                            !formData.has(cellName) && formData.append(cellName, this.formElements[cellName].currentValue);
                        }
                    } else if (!$Element.isInputChanged() && $Element.isVisible) {
                        $Element.errorStatus('Измените это поле');
                    }
                }

                //Если нет в списке, то просто добавляем в FormData
            } else {
                if (typeof this.formElements[cellName].currentValue === 'object') {
                    this.formElements[cellName].currentValue.forEach((item) => {
                        formData.append(cellName, item);
                    });
                } else if (cellClass === 'MultiDate') {
                    this.formElements[cellName].$inputs.forEach((input) => {
                        formData.append(input.getAttribute('name'), input.value);
                    });
                } else if (cellClass === 'ParamCheckbox') {
                    !formData.has(cellName) &&
                        this.formElements[cellName].$input.checked &&
                        formData.append(this.formElements[cellName].$input.name, this.formElements[cellName].$input.value);
                } else {
                    !formData.has(cellName) && formData.append(cellName, this.formElements[cellName].currentValue);
                }
            }
        });

        //Добавление групп полей для отслеживаемых инпутов
        groups.forEach(($group) => {
            const $firstGroupCell = $group.querySelector('.js-cell');
            const firstGroupCellName = $firstGroupCell.getAttribute('data-name');
            const groupCells = Array.from($group.querySelectorAll('.js-cell'));
            const isGroupVisible = this.formElements[firstGroupCellName].isVisible;
            let changedFieldsStatus = [];
            let isFieldInGroupChanged = false;

            //Проверяем, было ли изменено одно из полей
            for (let i = 0; i < groupCells.length; i++) {
                const cellName = groupCells[i].getAttribute('data-name');

                this.formElements[cellName].isInputChanged() ? changedFieldsStatus.push(true) : changedFieldsStatus.push(false);
            }

            isFieldInGroupChanged = changedFieldsStatus.includes(true);

            //Если было измененно, группа полей видна, и поля входят в список для отслеживания то добавляем
            if (isFieldInGroupChanged && isGroupVisible && checkedFields.includes(firstGroupCellName)) {
                groupCells.forEach(($item) => {
                    const cellName = $item.getAttribute('data-name');
                    const cellClass = $item.getAttribute('data-class');

                    if (cellClass === 'CellFias') {
                        formData.append(this.formElements[cellName].$postCode.getAttribute('name'), this.formElements[cellName].$postCode.value);
                        formData.append(this.formElements[cellName].$region.getAttribute('name'), this.formElements[cellName].$region.value);
                        formData.append(this.formElements[cellName].$city.getAttribute('name'), this.formElements[cellName].$city.value);
                        formData.append(this.formElements[cellName].$street.getAttribute('name'), this.formElements[cellName].$street.value);
                        formData.append(this.formElements[cellName].$house.getAttribute('name'), this.formElements[cellName].$house.value);
                        formData.append(this.formElements[cellName].$building.getAttribute('name'), this.formElements[cellName].$building.value);
                        formData.append(this.formElements[cellName].$flat.getAttribute('name'), this.formElements[cellName].$flat.value);
                        formData.append(cellName, this.formElements[cellName].currentValue);
                    } else {
                        !formData.has(cellName) && formData.append(cellName, this.formElements[cellName].currentValue);
                    }
                });
            }

            //Еслни не было изменено, но при этом группа полей видна, выводим ошибку
            if (!isFieldInGroupChanged && isGroupVisible && checkedFields.includes(firstGroupCellName)) {
                groupCells.forEach(($item) => {
                    const cellName = $item.getAttribute('data-name');

                    this.formElements[cellName].errorStatus('Измените одно из полей группы');
                });
            }
        });

        return formData;
    }

    addFormElement($el, options) {
        const elName = $el.getAttribute('data-name');
        const elClassName = $el.getAttribute('data-class');

        switch (elClassName) {
            case 'CellInput':
                this.formElements[elName] = new CellInput($el, options);
                break;
            case 'CellInputMask':
                this.formElements[elName] = new CellInputMask($el, options);
                break;
            case 'CellDatepicker':
                this.formElements[elName] = new CellDatepicker($el, options);
                break;
            case 'CellSelect':
                this.formElements[elName] = new CellSelect($el, options);
                break;
            case 'CellFias':
                this.formElements[elName] = new CellFias($el, options);
                break;
            case 'CellTextarea':
                this.formElements[elName] = new CellTextarea($el, options);
                break;
            case 'Checkbox':
                this.formElements[elName] = new Checkbox($el, options);
                break;
            case 'RadioButton':
                this.formElements[elName] = new RadioButton($el, options);
                break;
            case 'ParamCheckbox':
                this.formElements[elName] = new ParamCheckbox($el, options);
                break;
            case 'ParamRadioButton':
                this.formElements[elName] = new ParamRadioButton($el, options);
                break;
            case 'MultiDate':
                this.formElements[elName] = new MultiDate($el, options);
                break;
			case 'DatepickerMinMax':
				this.formElements[elName] = new DatepickerMinMax($el, options);
				break;
            case 'Loader':
                this.formElements[elName] = new Loader($el, options);
                break;
            default:
                console.error('Unknown class');
                break;
        }
    }
}
