import './addPhone.scss';
import Form from '../../components/Form/Form';

(function () {
    const forms = document.querySelectorAll('.js-form');
    let formsCollection = {};

    forms.forEach(($form) => {
        const formElements = $form.querySelectorAll('.js-cell');
        const formName = $form.getAttribute('data-name');
        const $Form = new Form($form, submitForm);

        formElements.forEach(($el) => {
            $Form.addFormElement($el, {
                onChange: changeInput.bind(this, $form),
            });
        });

        formsCollection[formName] = $Form;
    });

    function changeInput($form) {
        const formName = $form.getAttribute('data-name');

        !formsCollection[formName].checkForm() ? formsCollection[formName].unblockingFormButton() : formsCollection[formName].blockingFormButton();
    }

    function submitForm() {
        this.$form.submit();
    }
})();
